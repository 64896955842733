import React, {
    PropsWithChildren,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from "react";
import Header from "./Header";
import Footer from "./Footer";
import getNavData from "./navData";
import "../../stylesheets/main.scss";
import { useEventListener } from "../../hooks/UseEventListener";
import {
    getUtmString,
    isBrowser,
    isWindow,
} from "../../shared/helpers";
import MemberMenu from "./MemberMenu";
import { navMenuItems } from "./MemberMenu/NavMenu";
import { IMenuItem } from "./MemberMenu/Interfaces/MenuItemsInterfaces";
import * as styles from "./Layout.module.scss";
import { layoutConfig } from "./layoutConfig";
import UserDataProvider from "../../contexts/DependencyContext";
import useWindowWidth from "../../hooks/useWindowWidth";
import { navDesktopMinWidth } from "./Header/Navbar";
import PrismicService from "../../services/PrismicService";
import SkeletonBlock from "../shared/SkeletonBlock";
import CookiesService from "../../services/CookiesService";
import { graphql, useStaticQuery } from "gatsby";

const Layout = (props: PropsWithChildren<{}>): ReactElement => {
    const navData = getNavData();
    const prevScroll = useRef(0);
    const [dashboardData, setDashboardData] = useState(null as any);
    const includePaths = Object.values(
        layoutConfig.membersLayoutIncludePath,
    );
    const excludePaths = Object.values(
        layoutConfig.membersLayoutExcludePath,
    );

    const onScrolling = () => {
        if (!isBrowser()) return;
        const { classList } = document.body;
        const classes = {
            scroll: "isScrolling",
            down: "scrollingDown",
            up: "scrollingUp",
        };
        const computedStyle = getComputedStyle(
            document.documentElement,
        );
        const socialMediaWrapperHeight = parseFloat(
            computedStyle.getPropertyValue("--socialMediaIconHeight"),
        );

        if (window.scrollY > 0) {
            classList.add(classes.scroll);
            if (
                prevScroll.current < window.scrollY &&
                window.scrollY - prevScroll.current >
                    socialMediaWrapperHeight
            ) {
                classList.add(classes.down);
                classList.remove(classes.up);
            } else if (
                prevScroll.current > window.scrollY &&
                prevScroll.current - window.scrollY >
                    socialMediaWrapperHeight
            ) {
                classList.add(classes.up);
                classList.remove(classes.down);
            }
        } else {
            classList.remove(classes.scroll);
            if (classList.contains(classes.down))
                classList.remove(classes.down);
            if (classList.contains(classes.up))
                classList.remove(classes.up);
        }

        prevScroll.current = window.scrollY;
    };

    useEventListener("scroll", onScrolling);

    const statusClass = (code: string) => {
        switch (code) {
            case "1":
                return styles.active;
            case "2":
                return styles.freeze;
            case "3":
                return styles.inactive;
            default:
                break;
        }
    };

    const statusName = (code: string) => {
        switch (code) {
            case "1":
                return "Active";
            case "2":
                return "Freeze";
            case "3":
                return "Inactive";
            default:
                break;
        }
    };

    const isDesktop = useWindowWidth(navDesktopMinWidth);

    const getPrismicData = async () => {
        const prismicInstance = await PrismicService.getInstance();
        const dashboardContent = await prismicInstance.getDocuments(
            "dashboard_content",
        );
        return dashboardContent.results;
    };

    const handleData = async () => {
        const prismicDashboard = await getPrismicData();
        setDashboardData(prismicDashboard[0].data);
    };

    function addUtmCookies() {
        if (location.search.length > 0) {
            let searchParams = new URLSearchParams(location.search);
            let parsedParams = getUtmString(searchParams);
            if (parsedParams !== null) {
                CookiesService.setCookie(
                    "utm",
                    getUtmString(searchParams),
                );
            }
        }
    }

    useEffect(() => {
        handleData();
        addUtmCookies();
    }, []);

    // add content to members pages (menu and )
    let childContent;
    if (
        includePaths.includes(isWindow ? location.pathname : "") &&
        !excludePaths.includes(isWindow ? location.pathname : "")
    ) {
        childContent = (
            <>
                <section
                    id="status"
                    className={`${styles.statusWrapper} print-hidden`}
                >
                    {dashboardData && (
                        <img
                            className={styles.imgBackground}
                            src={
                                dashboardData.pattern_banner &&
                                dashboardData.pattern_banner.url
                                    ? dashboardData.pattern_banner.url
                                    : "/assets/image/MemberPattern.png"
                            }
                        />
                    )}
                    {!dashboardData && (
                        <div className={styles.imgBackground}>
                            <SkeletonBlock
                                styles={{
                                    height: "100%",
                                    width: "100%",
                                }}
                                rows={1}
                            />
                        </div>
                    )}
                    <div className={styles.info}>
                        <h3>
                            {isWindow
                                ? localStorage.getItem("name")
                                : ""}
                        </h3>
                        <h4>
                            Membership -{" "}
                            <span
                                className={`${styles.status} ${isWindow && statusClass(localStorage.getItem("status") as string)}`}
                            >
                                {isWindow
                                    ? statusName(
                                          localStorage.getItem(
                                              "status",
                                          ) as string,
                                      )
                                    : ""}
                            </span>
                        </h4>
                        <small>
                            Member ID #
                            {isWindow
                                ? localStorage.getItem("role")
                                : ""}{" "}
                        </small>
                        <h4>
                            Balance Due - $
                            {isWindow
                                ? localStorage.getItem("balance")
                                : ""}
                        </h4>
                    </div>
                </section>
                {!isDesktop && <MemberMenu items={navMenuItems} />}
                <div className={styles.childrensWrapper}>
                    {isDesktop && <MemberMenu items={navMenuItems} />}
                    <main
                        className={`${styles.mainMemberContent} print-block`}
                    >
                        {props.children}
                    </main>
                </div>
            </>
        );
    } else {
        childContent = props.children;
    }

    return (
        <UserDataProvider>
            <div className="mainWrapper">
                <Header navData={navData} location={props} />
                {childContent}
                <Footer navData={navData} />
            </div>
        </UserDataProvider>
    );
};

export default Layout;
